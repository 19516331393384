.root {
  width: 100%;
  background-color: var(--color-bg-elevation-1);
  border-radius: var(--border-radius-elevation-3);
  outline: var(--border-width-1) solid var(--color-bd-elevation-3);
  overflow: hidden;
  height: 100%;
}

.header {
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--inner-spacing-2) var(--inner-spacing-4);
  border-bottom: 1px solid var(--color-bd-elevation-1);
}

.logo {
  display: flex;
  align-items: center;
  box-sizing: content-box;
  padding: var(--inner-spacing-2);
  background: var(--color-bg-elevation-2, #fff);
  border-radius: var(--radius-elevation-1, 6px);
}

.logo img {
  border-radius: initial;
}

.user {
  border-radius: var(--radius-elevation-1, 6px);
  border: 1px solid var(--color-bd-elevation-3, #e9edf4);
  background: var(--color-bg-elevation-2, #fff);
  padding: var(--inner-spacing-2) var(--inner-spacing-3);
}

.disclaimer {
  color: var(--color-fg-neutral-subtle);
}
